import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useToken from "../authentication/token";
import RxnTable from "../layout/components/RxnTable";
import { IconAddOutline } from "../layout/components/SVGs";
import toast from "react-hot-toast";
import Select from "react-select";

export default function TagsList() {
  //Authentication Check
  const { token, verifyToken, kickOutGuest, role } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (role !== "admin") {
      navigate("/");
    }
    token ? verifyToken() : kickOutGuest();
  }, []);

  const [tagList, setTagList] = useState({
    columns: [
      {
        label: "Tag ID",
        field: "value",
      },
      {
        label: "Tag Name",
        field: "label",
      },
      {
        label: "Tag Description",
        field: "description",
      },
    ],
    data: [],
  });
  const [tag, setTag] = useState({
    tagId: "",
    tagName: "",
    tagDescription: "",
    taggedRxns: [],
    otherRXNs: [],
  });
  const [loading, setLoading] = useState(false);

  const [showAddTag, setShowAddTag] = useState(false);
  const handleCloseAdd = () => setShowAddTag(false);
  const handleShowAdd = () => {
    setShowAddTag(true);
  };

  const [showEditTag, setShowEditTag] = useState(false);
  const handleCloseEdit = () => {
    setShowEditTag(false);
    setTag({
      tagId: "",
      tagName: "",
      tagDescription: "",
      taggedRxns: [],
      otherRXNs: [],
    });
  };
  const handleShowEdit = (row) => {
    setShowEditTag(true);
    getTagData(row.value);
  };

  const [showDeleteTag, setShowDeleteTag] = useState(false);
  const handleCloseDelete = () => {
    setShowDeleteTag(false);
    setTag({
      tagId: "",
      tagName: "",
      tagDescription: "",
      taggedRxns: [],
      otherRXNs: [],
    });
  };
  const handleShowDelete = (row) => {
    setShowDeleteTag(true);
    setTag({
      ...tag,
      tagId: row.value,
      tagName: row.label,
    });
  };

  function getTags() {
    axios({
      method: "GET",
      url: "/reactionClass/tagList",
    }).then((res) => {
      setTagList({ ...tagList, data: res.data.tags });
    });
  }

  function getTagData(id) {
    axios({
      method: "GET",
      url: "/admin/manage/tags/" + id,
    })
      .then((res) => {
        setTag({
          ...tag,
          tagId: res.data.tag.value,
          tagName: res.data.tag.label,
          tagDescription: res.data.tag.description,
          taggedRxns: res.data.tag.tagged_rxns,
          otherRxns: res.data.tag.other_rxns,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleSubmitCreate() {
    setLoading(true);
    let tagData = {
      tagName: tag.tagName,
      tagDescription: tag.tagDescription,
    };
    axios({
      method: "POST",
      url: "/admin/manage/tags/add",
      data: tagData,
    })
      .then((res) => {
        getTags();
        toast.success(res.data.msg);
        handleCloseAdd();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }

  function handleSubmitEdit() {
    setLoading(true);
    let tagData = {
      tagID: tag.tagId,
      tagName: tag.tagName,
      tagDescription: tag.tagDescription,
      taggedRXNs: tag.taggedRxns,
    };
    axios({
      method: "POST",
      url: "/admin/manage/tags/update",
      data: tagData,
    })
      .then((res) => {
        getTags();
        toast.success(res.data.msg);
        handleCloseEdit();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }

  function handleSubmitDelete() {
    setLoading(true);
    let tagData = {
      tagId: tag.tagId,
    };
    axios({
      method: "POST",
      url: "/admin/manage/tags/delete",
      data: tagData,
    })
      .then((res) => {
        getTags();
        toast.success(res.data.msg);
        handleCloseDelete();
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }

  useEffect(() => {
    getTags();
  }, []);
  return (
    <>
      {/* 
      ***
      Reaction Table
      ***
       */}
      <RxnTable
        striped
        bordered
        hover
        data={tagList}
        loading={loading}
        token={token}
        actions={{
          add: true,
          addLogic: () => (
            <Button
              onClick={() => {
                handleShowAdd();
              }}
            >
              <IconAddOutline />
            </Button>
          ),
          edit: true,
          editLogic: (row) => {
            handleShowEdit(row);
          },
          delete: true,
          deleteLogic: (row) => {
            handleShowDelete(row);
          },
        }}
      ></RxnTable>

      {/* 
      ***
      Modal for creating tags
      ***
       */}
      <Modal
        size="xl"
        backdrop="static"
        show={showAddTag}
        onHide={handleCloseAdd}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create A New Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCreateTag">
              <Form.Label>Tag Name</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter tag name"
                onChange={(e) => setTag({ ...tag, tagName: e.target.value })}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Tag Description</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter tag description"
                onChange={(e) =>
                  setTag({ ...tag, tagDescription: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitCreate()}
          >
            Create Tag
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 
      ***
      Modal for editing tags
      ***
       */}
      <Modal
        size="xl"
        backdrop="static"
        show={showEditTag}
        onHide={handleCloseEdit}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formCreateTag">
              <Form.Label>Tag Name</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter tag name"
                value={tag.tagName}
                onChange={(e) => setTag({ ...tag, tagName: e.target.value })}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Tag Description</Form.Label>
              <Form.Control
                className="mb-3"
                required
                type="text"
                placeholder="Enter tag description"
                value={tag.tagDescription}
                onChange={(e) =>
                  setTag({ ...tag, tagDescription: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tagged Reactions</Form.Label>
              <Select
                value={tag.taggedRxns}
                options={tag.otherRxns}
                isMulti
                isSearchable
                className="mb-3"
                placeholder="Select Reactions"
                onChange={(rxn) => setTag({ ...tag, taggedRxns: rxn })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            onClick={() => handleSubmitEdit()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 
      ***
      Modal for deleting tags
      ***
       */}
      <Modal
        size="xl"
        backdrop="static"
        show={showDeleteTag}
        onHide={handleCloseDelete}
      >
        <Modal.Header closeButton>
          <Modal.Title> Delete Tag</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure you want to delete "{tag.tagName}"</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loading}
            variant="danger"
            type="submit"
            onClick={() => handleSubmitDelete()}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
