import React, { useEffect, useState } from "react";
import Base from "../layout/BaseTemplate";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Table, Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IconArrowLeft } from "../layout/components/SVGs";

export default function SynthonDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [synDetail, setSynDetail] = useState({
    synId: "",
    synName: "",
    synSmarts: "",
    synRxns: [],
  });

  function getSynDetail() {
    axios({
      method: "GET",
      url: "/reactionClass/synthon/" + id,
    }).then((res) => {
      setSynDetail({
        synId: res.data.synthon_id,
        synName: res.data.synthon_name,
        synSmarts: res.data.synthon_smarts,
        synRxns: res.data.rxn_list,
      });
    });
  }

  function DisplayImage() {
    const [imageSource, setImageSource] = useState(null);
    const localImageUrl = `/img/synthons/SYN-${id}.png`;
    const apiUrl = `https://smarts.plus/smartsview/download_rest?;depsymbols=0;vmode=0;textdesc=0;smartsheading=0;smarts=${encodeURIComponent(
      synDetail.synSmarts
    )}`;

    //Using img object, check whether img path is real by loading in the img
    const img = new Image();
    img.src = localImageUrl;

    //If img exist, then img src will the cached img
    img.onload = () => {
      setImageSource(localImageUrl);
    };

    //If no img in cache, then img src will be API call
    img.onerror = () => {
      setImageSource(apiUrl);
    };

    //return image
    return <img width={500} src={imageSource} alt={`SYN-${id}.png`} />;
  }

  function renderRxns() {
    let result;
    if (synDetail.synRxns.length === 0) {
      result = (
        <tr>
          <td>N/A</td>
        </tr>
      );
    } else {
      result = synDetail.synRxns.map((value, index) => (
        <tr>
          <td>{value.rxn_id}</td>
          <td>{value.rxn_name}</td>
        </tr>
      ));
    }
    return result;
  }

  useEffect(() => {
    getSynDetail();
  }, []);

  return (
    <Base>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Button
                className="mt-2"
                variant="outline-primary"
                onClick={() => {
                  navigate("/synthons");
                }}
              >
                <IconArrowLeft />
              </Button>
            </Col>
            <Col className="centerText mt-2">
              <h2>{synDetail.synName}</h2>
            </Col>
            <Col></Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>ID</th>
                <td>{synDetail.synId}</td>
              </tr>
              <tr>
                <th>SMARTS</th>
                <td>{synDetail.synSmarts}</td>
              </tr>
              <tr>
                <th>Image</th>
                <td className="centerText">
                  <DisplayImage />
                </td>
              </tr>
              <tr>
                <th>Reactions</th>
                <td>
                  <Table striped bordered hover>
                    <tbody>{renderRxns()}</tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Base>
  );
}
