import React, { useEffect, useState } from "react";
import { forwardRef } from "react";
import {
  Button,
  Table,
  Pagination,
  Navbar,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { IconPencilFill, IconDelete, IconViewShow } from "../components/SVGs";
import { LoadingScreens } from "./LoadingScreens";

const RxnTable = forwardRef((props, ref) => {
  const bordered = props.bordered;
  const striped = props.striped;
  const hover = props.hover;
  const token = props.token ? props.token : null;
  const data = props.data ? props.data : 0;
  const columnLength = props.data ? props.data.columns.length : 0;
  const tags = props.tags ? props.tags : null;
  const actions = props.actions ? props.actions : null;
  const loading = props.loading ? props.loading : false;

  const [total, setTotal] = useState(props.data ? props.data.length : 0);
  const [currentTag, setCurrentTag] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [search, setSearch] = useState("");

  // const setChildString = (value) => props.updateChildString(value);

  // useEffect(() => {
  //   setChildString(search);
  // }, [search]);

  const filtered = data.data
    ? data.data.filter((item) => {
        let show = false;
        if (search === "" && currentTag === null) {
          return true;
        } else {
          data.columns.map((column) => {
            if (
              search === "" &&
              currentTag !== null &&
              item.tags.includes(currentTag)
            ) {
              show = true;
            } else if (
              search !== "" &&
              currentTag === null &&
              String(item[column.field])
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              show = true;
            }
          });
        }

        return show;
      })
    : null;

  function buildPagination() {
    let pagination = [];
    //show 2 pages before and after current page
    let start = page - 2;
    let end = page + 2;
    //if current page is less than 3, show first 5 pages
    if (page < 3) {
      start = 1;
      end = 5;
    }
    //if current page is greater than total pages - 2, show last 5 pages
    if (page > Math.ceil(total / perPage) - 2) {
      start = Math.ceil(total / perPage) - 4;
      end = Math.ceil(total / perPage);
    }
    //if total pages is less than 5, show all pages
    if (Math.ceil(total / perPage) < 5) {
      start = 1;
      end = Math.ceil(total / perPage);
    }
    //if start is less than 1, set to 1
    if (start < 1) {
      start = 1;
    }
    //if end is greater than total pages, set to total pages
    if (end > Math.ceil(total / perPage)) {
      end = Math.ceil(total / perPage);
    }
    //build pagination
    for (let i = start; i <= end; i++) {
      pagination.push(
        <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
          {i}
        </Pagination.Item>
      );
    }
    return pagination;
  }

  function dynamicTableTd(row) {
    let temp = [];
    for (let i = 0; i < columnLength; i++) {
      temp.push(
        <td key={"tableBodyFieldCol" + i}>{row[data.columns[i]["field"]]}</td>
      );
    }
    return temp;
  }

  function renderActions(row) {
    let actionButtons = [];
    if (actions.view) {
      actionButtons.push(
        <Button
          key={"view"}
          variant="outline-primary"
          onClick={() => {
            actions.viewLogic(row);
          }}
        >
          <IconViewShow />
        </Button>
      );
    }
    if (actions.edit) {
      actionButtons.push(
        <Button
          key={"edit"}
          className=" m-1"
          variant="outline-primary"
          onClick={() => {
            actions.editLogic(row);
          }}
        >
          <IconPencilFill />
        </Button>
      );
    }
    if (actions.delete) {
      actionButtons.push(
        <Button
          key={"delete"}
          className=" m-1"
          variant="outline-danger"
          onClick={() => {
            actions.deleteLogic(row);
          }}
        >
          <IconDelete />
        </Button>
      );
    }
    return actionButtons;
  }

  useEffect(() => {
    setPage(1);
    data && setTotal(data.data.length);
  }, [data]);

  useEffect(() => {
    data && setTotal(filtered.length);
    setPage(1);
  }, [search]);

  return (
    <>
      <Navbar>
        <Navbar.Collapse>
          {tags ? (
            <DropdownButton title={currentTag ? currentTag : "Sort By Tag"}>
              {tags.tags &&
                tags.tags.map((tag, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setCurrentTag(tag.label);
                      setSearch("");
                    }}
                  >
                    {tag.label}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
          ) : (
            <></>
          )}
          <Dropdown>
            <Dropdown.Toggle variant="" id="dropdown-basic">
              {perPage}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setPerPage(10)}>10</Dropdown.Item>
              <Dropdown.Item onClick={() => setPerPage(20)}>20</Dropdown.Item>
              <Dropdown.Item onClick={() => setPerPage(50)}>50</Dropdown.Item>
              <Dropdown.Item onClick={() => setPerPage(100)}>100</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          Per Page
        </Navbar.Collapse>

        <Navbar.Collapse className="justify-content-end align-middle">
          <Form.Group className="px-1">
            <Form.Control
              id="searchBar"
              type="text"
              value={search}
              placeholder="search"
              onChange={(e) => {
                setSearch(e.target.value);
                setCurrentTag(null);
              }}
            />
          </Form.Group>
          {actions && actions.add && token ? actions.addLogic() : ""}
        </Navbar.Collapse>
      </Navbar>
      <Navbar>
        <Navbar.Collapse className="justify-content-center align-middle">
          <Pagination style={{ marginBottom: "auto" }}>
            <Pagination.First onClick={() => setPage(1)} />
            <Pagination.Prev
              onClick={() => (page <= 1 ? setPage(1) : setPage(page - 1))}
            />
            {buildPagination()}
            <Pagination.Next
              onClick={() =>
                page >= Math.ceil(total / perPage)
                  ? setPage(Math.ceil(total / perPage))
                  : setPage(page + 1)
              }
            />
            <Pagination.Last
              onClick={() => setPage(Math.ceil(total / perPage))}
            />
          </Pagination>
        </Navbar.Collapse>
      </Navbar>

      {loading ? (
        <LoadingScreens />
      ) : data ? (
        <Table striped={striped} bordered={bordered} hover={hover} responsive>
          <thead key={"tableHead"}>
            <tr key={"tableRow"}>
              {data.columns.map((col, index) => (
                <th key={"tableCol" + index}>{col.label}</th>
              ))}
              {actions ? <th style={{ width: "10%" }}>View</th> : null}
            </tr>
          </thead>
          <tbody>
            {filtered != 0 ? (
              filtered
                .slice((page - 1) * perPage, page * perPage)
                .map((row, index) => (
                  <tr key={"tableBodyRow" + index}>
                    {dynamicTableTd(row)}
                    {actions ? (
                      <td key={"tableBodyCol" + index} className="action">
                        {renderActions(row)}
                      </td>
                    ) : null}
                  </tr>
                ))
            ) : (
              <tr>
                <td>
                  <h1>No Results Found</h1>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      ) : (
        <h1 className="centerText">No Data Found</h1>
      )}
      <Navbar>
        <Navbar.Collapse className="justify-content-center align-middle">
          <Pagination style={{ marginBottom: "auto" }}>
            <Pagination.First onClick={() => setPage(1)} />
            <Pagination.Prev
              onClick={() => (page <= 1 ? setPage(1) : setPage(page - 1))}
            />
            {buildPagination()}
            <Pagination.Next
              onClick={() =>
                page >= Math.ceil(total / perPage)
                  ? setPage(Math.ceil(total / perPage))
                  : setPage(page + 1)
              }
            />
            <Pagination.Last
              onClick={() => setPage(Math.ceil(total / perPage))}
            />
          </Pagination>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
});

export default RxnTable;
