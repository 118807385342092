import React from "react";
import { Container } from "react-bootstrap";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";
import "../App.css";

export default function Base({ children }) {
  return (
    <div>
      <NavigationBar />
      <br />
      <Container style={{ margin: "auto", width: "90%" }} fluid>
        {children}
      </Container>
      <br></br>
      <Footer />
    </div>
  );
}
