import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

export default function useToken() {
  const [firstName, setFirstName] = useState(getFirstName());
  const [lastName, setLastName] = useState(getLastName());
  const [email, setEmail] = useState(getEmail());
  const [organization, setOrganization] = useState(getOrg());
  const [role, setRole] = useState(getRole());
  const [token, setToken] = useState(getToken());
  const navigate = useNavigate();

  function getFirstName() {
    const firstName = localStorage.getItem("firstName");
    return firstName && firstName;
  }

  function getLastName() {
    const lastName = localStorage.getItem("lastName");
    return lastName && lastName;
  }

  function getEmail() {
    const email = localStorage.getItem("email");
    return email && email;
  }

  function getOrg() {
    const org = localStorage.getItem("organization");
    return org && org;
  }

  function getToken() {
    const token = localStorage.getItem("token");
    return token && token;
  }

  function getRole() {
    const role = localStorage.getItem("role");
    return role && role;
  }

  function saveToken(firstName, lastName, email, organization, role, token) {
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("lastName", lastName);
    localStorage.setItem("email", email);
    localStorage.setItem("organization", organization);
    localStorage.setItem("token", token);
    localStorage.setItem("role", role);

    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setOrganization(organization);
    setRole(role);
    setToken(token);
  }

  function removeToken() {
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("email");
    localStorage.removeItem("organization");
    localStorage.removeItem("role");
    localStorage.removeItem("token");

    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setOrganization(null);
    setRole(null);
    setToken(null);

    axios({
      method: "POST",
      url: "/auth/logout",
    }).then((res) => {
      toast.success(res.data.msg);
      kickOutGuest();
    });

    setTimeout(() => {
      navigate("/login");
      toast.dismiss();
    }, 1000);
  }

  async function verifyToken() {
    await axios({
      method: "GET",
      url: "/auth/verify",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => {
        if (res.status === 200) {
          setToken(res.data.access_token);
          localStorage.setItem("token", res.data.access_token);
        }
        else if ( res.status === 201 ) {
          console.log(res.data.msg);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          removeToken();
        } else {
          console.log(err.response);
        }
        console.log(err);
      });
  }

  function kickOutGuest() {
    toast.error("Please login");
    navigate("/login");
  }

  function checkIfAdmin() {
    if (role !== "admin") {
      toast.error("YOU SHALL NOT PASS");
      navigate("/");
    }
  }

  return {
    saveToken,
    removeToken,
    verifyToken,
    kickOutGuest,
    checkIfAdmin,
    firstName,
    lastName,
    email,
    organization,
    role,
    token,
  };
}
