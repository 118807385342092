import React, { useState, useEffect } from "react";
import Base from "../layout/BaseTemplate";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import useToken from "../authentication/token";
import RxnTable from "../layout/components/RxnTable";
import { Button } from "react-bootstrap";
import { IconAddOutline } from "../layout/components/SVGs";

export default function Reactions() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    columns: [
      {
        label: "Rxn ID",
        field: "rxn_id",
      },
      {
        label: "Short Name",
        field: "rxn_name",
      },
      {
        label: "Product: Reagents",
        field: "description",
      },
      {
        label: "Rxn SMARTS",
        field: "smarts",
      },
    ],
    data: [],
  });

  const [tags, setTags] = useState([]);
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  // const [childString, setChildString] = useState("");

  function getTagsList() {
    axios({
      method: "GET",
      url: "/reactionClass/tagList",
    }).then((res) => {
      setTags(res.data);
    });
  }

  async function getRxns() {
    setLoading(true);
    await axios({
      method: "GET",
      url: "/reactionClass/reactions",
    }).then((res) => {
      setData({ ...data, data: res.data });
      localStorage.setItem("rxnData", JSON.stringify(res));
    });
    setLoading(false);
  }

  useEffect(() => {
    getTagsList();
    if (localStorage.getItem("rxnData")) {
      setData({
        ...data,
        data: JSON.parse(localStorage.getItem("rxnData"))["data"],
      });
    } else {
      getRxns();
    }
  }, []);

  return (
    <Base>
      <RxnTable
        striped
        bordered
        hover
        data={data}
        tags={tags}
        token={token}
        loading={loading}
        actions={{
          view: true,
          viewLogic: (target) => navigate("/reaction/" + target.rxn_id),
          add: true,
          addLogic: () => (
            <Button onClick={() => navigate("/register_reaction")}>
              <IconAddOutline />
            </Button>
          ),
        }}
        // updateChildString={(value) => setChildString(value)}
      ></RxnTable>
      {/* {console.log(childString)} */}
    </Base>
  );
}
