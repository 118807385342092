import React, { useEffect, useState } from "react";
import axios from "axios";
import Base from "../layout/BaseTemplate";
import RxnTable from "../layout/components/RxnTable";
import { useNavigate } from "react-router-dom";

export default function SynthonsPage() {
  const navigate = useNavigate();

  const [data, setData] = useState({
    columns: [
      {
        label: "ID",
        field: "synthon_id",
      },
      {
        label: "Name",
        field: "synthon_name",
      },
      {
        label: "SMARTS",
        field: "synthon_smarts",
      },
    ],
    data: [],
  });
  const [loading, setLoading] = useState(false);

  async function getSynthons() {
    setLoading(true);
    await axios({
      method: "GET",
      url: "/reactionClass/synthonList",
    }).then((res) => {
      setData({ ...data, data: res.data });
      localStorage.setItem("synData", JSON.stringify(res));
    });
    setLoading(false);
  }

  useEffect(() => {
    if (localStorage.getItem("synData")) {
      setData({
        ...data,
        data: JSON.parse(localStorage.getItem("synData"))["data"],
      });
    } else {
      getSynthons();
    }
  }, []);

  return (
    <Base>
      <RxnTable
        striped
        bordered
        hover
        data={data}
        loading={loading}
        actions={{
          view: true,
          viewLogic: (target) => navigate("/synthon/" + target.synthon_id),
        }}
      ></RxnTable>
    </Base>
  );
}
