import React from "react";
import Base from "../layout/BaseTemplate";
import toast from "react-hot-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import useToken from "./token";

function LoginForm() {
  const [userEmail, setEmail] = useState("");
  const [userPassword, setPassword] = useState("");
  const { saveToken } = useToken();
  const [isDisabled, setDisabled] = useState(false);

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const handleLogin = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      e.preventDefault();

      //User Email being sent via POST
      const data = {
        email: userEmail,
        password: userPassword,
      };

      //API Backend Call to Login
      axios({
        method: "POST",
        url: "/auth/login",
        data: data,
      })
        .then((res) => {
          setDisabled(true);
          saveToken(
            res.data.user.first_name,
            res.data.user.last_name,
            res.data.user.email,
            res.data.user.organization,
            res.data.user.role,
            res.data.access_token
          );

          toast.success(res.data.msg);

          setTimeout(() => {
            navigate("/");
            toast.dismiss();
          }, 1000);
        })
        .catch((err) => {
          return toast.error(err.response.data.msg);
        });
    }
  };

  return (
    <Base>
      <br />
      <Row className="justify-content-md-center">
        <Col lg="4">
          <Container fluid className="page-content">
            <Card className="text-center">
              <Card.Header>
                <h1>Login</h1>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleLogin} noValidate validated={validated}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          required
                          type="password"
                          placeholder="Enter password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        className="mb-3"
                        variant="primary"
                        type="submit"
                        disabled={isDisabled}
                      >
                        {isDisabled ? "Submitting" : "Submit"}
                      </Button>
                      <p>
                        Don't have an account?{" "}
                        <Link to="/register">Register here</Link>
                      </p>
                      <p>
                        Forgot your password?{" "}
                        <Link to="/forgotPassword">Click here</Link>
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
      {/* {giraffe} */}
    </Base>
  );
}

export default LoginForm;
