import React from "react";
import Base from "../layout/BaseTemplate";
import { Container, Col, Row, Card } from "react-bootstrap";
import useToken from "../authentication/token";
import { Toaster } from "react-hot-toast";

export default function HomePage() {
  const { firstName, lastName, token } = useToken();
  return (
    <Base>
      <Container fluid className="page-content square rounded">
        <h1>
          This is Chemistry Commons
          {token ? " " + firstName + " " + lastName : ""}!
        </h1>
        <Card>
          <Card.Body>
            <p className="lead">
            The Chemistry Commons connects synthetic organic chemists with
            biologists seeking new reagents for biology, each assisted by their
            medicinal and computational chemistry colleagues.
            </p>
            <p className="lead text-left">
            To get started:
            <ul>
              <li>
                Chemists with a new reaction who wish to code it should look at some
                of our tutorials on how to code new reactions.
              </li>
              <li>
                Biologists and medicinal chemists seeking new molecules can search
                the databases compounds already in the chemistry commons in <a target="_blank" rel="noopener noreferrer" href='https://arthorcc.docking.org'>ArthorCC</a> 
                and <a target="_blank" rel="noopener noreferrer" href='https://swcc.docking.org'>SWCC</a>.
              </li>
              <li>
                Read our paper about the Chemistry Commons (man. in prep)
              </li>
              <li>
                Read the first successful use of the Chemistry Commons:<br />
                Kaplan et al, Nature, 2022 Oct;610(7932):582-591. <a target="_blank"  rel="noopener noreferrer" href="http://dx.doi.org/10.1038/s41586-022-05258-z">[DOI]</a>&nbsp;
                <a target="_blank" rel="noopener noreferrer" href="https://pubmed.ncbi.nlm.nih.gov/36171289/">[PubMed]</a>
              </li>
            </ul>
            The Chemistry Commons is provided by the Irwin and Shoichet
            Laboratories in the Department of Pharmaceutical Chemistry at the
            University of California, San Francisco (UCSF). To cite the Chemistry
            Commons, please cite the paper above.
            <br />
            <b>Caveat Emptor</b>: We accept no responsibility for errors arising from the
            use of this service. It is provided in the hope that it will be
            useful, but you must use it at your own risk.
            </p>
          </Card.Body>
        </Card>
      </Container>
      <Toaster />
    </Base>
  );
}
