import React from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Card,
  Container,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Base from "../layout/BaseTemplate";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as formik from "formik";
import * as yup from "yup";

function RegisterForm() {
  const navigate = useNavigate();

  // Modal States
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [clickedRow, setClickedRow] = useState([]);

  //Validation
  const { Formik } = formik;

  const schema = yup.object().shape({
    firstName: yup.string().required().min(1),
    lastName: yup.string().required(),
    email: yup.string().required().email("Must be a valid email"),
    organization: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup.string().required(),
  });

  const registerUser = (e) => {
    if (e.password !== e.confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      const data = {
        firstName: e.firstName,
        lastName: e.lastName,
        organization: e.organization,
        email: e.email,
        password: e.password,
      };

      axios({
        method: "POST",
        url: "/auth/register",
        data: data,
      })
        .then((res) => {
          setClickedRow(res);
          handleShow();
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.msg);
        });
    }
  };

  return (
    <Base>
      <br />
      <Row className="justify-content-md-center">
        <Col lg="5">
          <Container fluid className="page-content">
            <Card className="text-center">
              <Card.Header>
                <h1>Register</h1>
              </Card.Header>
              <Card.Body>
                <Formik
                  validationSchema={schema}
                  onSubmit={registerUser}
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    organization: "",
                    password: "",
                    confirmPassword: "",
                  }}
                >
                  {({
                    values,
                    touched,
                    errors,
                    validateForm,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                  }) => (
                    <Form onSubmit={(e) => handleSubmit(e)} noValidate>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="First Name"
                              isValid={touched.firstName && !errors.firstName}
                              isInvalid={touched.firstName && errors.firstName}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="lastName"
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Last Name"
                              isValid={touched.lastName && !errors.lastName}
                              isInvalid={touched.lastName && errors.lastName}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Email"
                              isValid={touched.email && !errors.email}
                              isInvalid={touched.email && errors.email}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Organization</Form.Label>
                            <Form.Control
                              type="text"
                              name="organization"
                              value={values.organization}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Organization"
                              isValid={
                                touched.organization && !errors.organization
                              }
                              isInvalid={
                                touched.organization && errors.organization
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Password"
                              isValid={touched.password && !errors.password}
                              isInvalid={
                                (touched.password && errors.password) ||
                                values.password !== values.confirmPassword
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              type="password"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Confirm Password"
                              isValid={
                                touched.confirmPassword &&
                                !errors.confirmPassword
                              }
                              isInvalid={
                                (touched.confirmPassword &&
                                  errors.confirmPassword) ||
                                values.password !== values.confirmPassword
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            variant="primary"
                            type="submit"
                            onClick={validateForm}
                          >
                            Register
                          </Button>
                          <p>
                            Already have an account?{" "}
                            <Link to="/login">Login here</Link>
                          </p>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
      <Modal size="sm" backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header>
          <h4>Message</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>{clickedRow.data ? clickedRow.data.msg : ""}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="justify-content-md-center">
          <Button
            onClick={() => {
              navigate("/login");
            }}
          >
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal>
    </Base>
  );
}
export default RegisterForm;
