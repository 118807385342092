import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import Base from "../layout/BaseTemplate";
import useToken from "../authentication/token";
import axios from "axios";
import { PlusButton, CloseButton } from "react-svg-buttons";
import Autocomplete from "react-autocomplete";
import Select from "react-select";
import toast from "react-hot-toast";

function RegisterReactionForm() {
  // Authentication Check
  const { token, verifyToken, kickOutGuest, email, role } = useToken();
  useEffect(() => {
    token ? verifyToken() : kickOutGuest();
  }, []);

  // Grab Reaction and Synthon Lists. Store them in their states.
  const [rxnId, setRxnId] = useState(null);
  const [rxnList, setRxnList] = useState([]);
  const [synthonList, setSynthonList] = useState([]);
  const [tags, setTags] = useState([]);
  const [currentTab, setCurrentTab] = useState("Synthon1");

  function getRxnList() {
    axios({
      method: "GET",
      url: "/reactionClass/reactions",
    }).then((res) => {
      setRxnList(res.data);
    });
  }

  function getTags() {
    return axios
      .get("/admin/manage/tags_list")
      .then((res) => {
        setTags(res.data.tags);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getReactionDetail(id) {
    axios({
      method: "GET",
      url: "/reactionClass/reaction/" + id,
    }).then((res) => {
      let data = res.data.synthons;
      let newSyn = [syn];

      for (let index = 0; index < data.length - 1; index++) {
        addSynField();
        newSyn.push({
          synName: "",
          inSmarts: "",
          exSmarts: "",
        });
      }

      for (let index = 0; index < data.length; index++) {
        newSyn[index].synName = data[index].synthon_name;
        newSyn[index].inSmarts = data[index].synthon_inclusion_smarts
          ? data[index].synthon_inclusion_smarts.join("\n")
          : "";
        newSyn[index].exSmarts = data[index].synthon_exclusion_smarts
          ? data[index].synthon_exclusion_smarts.join("\n")
          : "";
      }
      setSyn(newSyn);
    });
  }

  function getAllSynthons() {
    axios({
      method: "get",
      url: "/reactionClass/synthonList",
    }).then((res) => {
      setSynthonList(res.data);
    });
  }

  function renderReactionName(state, val) {
    return state.rxn_name.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  }

  function renderSynthonName(state, val) {
    return state.synthon_name.toLowerCase().indexOf(val.toLowerCase()) !== -1;
  }

  // Reaction State and Synthon States
  const [rxn, setRxn] = useState({
    rxnName: "",
    rxnDescription: "",
    rxnSmarts: "",
    rxnSmartsDescription: "",
  });
  const [syn, setSyn] = useState([
    {
      synName: "",
      inSmarts: "",
      exSmarts: "",
    },
  ]);

  // Triggers every change in synthon form
  const synFormChange = (index, e) => {
    let data = [...syn];
    data[index][e.target.id] = e.target.value;
    setSyn(data);
  };

  // Add New Synthon Form
  const addSynField = () => {
    let newSyn = {
      synName: "",
      inSmarts: "",
      exSmarts: "",
    };

    setSyn([...syn.slice(0, 3), newSyn]);
  };

  // Remove A Synthon Form
  const removeSynField = (index) => {
    let data = [...syn];
    data.splice(index, 1);
    setSyn(data);
  };

  // Handle User Reaction Submission
  const [validated, setValidated] = useState(false);

  const reactionSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true) {
      e.preventDefault();
      let formData = {
        ...rxn,
        synthons: syn.map((synthon) => {
          return {
            synName: synthon.synName,
            exSmarts: synthon.exSmarts.split("\n"),
            inSmarts: synthon.inSmarts.split("\n"),
          };
        }),
        reactionId: rxnId,
      };
      console.log(formData);
      axios({
        method: "POST",
        url: "/rxnRegistration/submit",
        data: formData,
      }).then((res) => {
        console.log(res);
        toast((t) => (
          <div>
            <Row>{res.data.msg}</Row>
            <Row>
              <Button onClick={() => toast.dismiss(t.id)}>Dismiss</Button>
            </Row>
          </div>
        ));
      });
    }
  };

  useEffect(() => {
    setRxn({ ...rxn, synthons: syn, email: email });
  }, [syn]);

  useEffect(() => {
    getRxnList();
    getAllSynthons();
    getTags();
  }, []);

  return (
    <Base>
      <Container fluid className="page-content">
        <Form onSubmit={reactionSubmit} noValidate validated={validated}>
          <Row>
            {/*  */}
            {/*  */}
            {/* Reaction Form */}
            {/*  */}
            {/*  */}
            <Col lg={6}>
              <Card className="mb-3">
                <Card.Header>
                  <h3>Register Reaction</h3>
                </Card.Header>
                <Card.Body>
                  <Form.Group className="mb-3" controlId="formRxnName">
                    <Form.Label>
                      Reaction Name<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <br />
                    <div className="autocomplete-wrapper">
                      <Autocomplete
                        value={rxn.rxnName ? rxn.rxnName : ""}
                        items={rxnList}
                        getItemValue={(item) => item.rxn_name}
                        shouldItemRender={renderReactionName}
                        renderInput={(props) => (
                          <input
                            required
                            className="form-control"
                            placeholder="Enter Reaction Name"
                            {...props}
                          />
                        )}
                        renderMenu={(item) => (
                          <div className="dropdown">{item}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "selected-item" : ""
                            }`}
                          >
                            {item.rxn_name}
                          </div>
                        )}
                        onChange={(event, val) =>
                          setRxn({ ...rxn, rxnName: val })
                        }
                        onSelect={(val, item) => {
                          setRxn({
                            ...rxn,
                            rxnName: item.rxn_name,
                            rxnDescription: item.description,
                            rxnSmarts: item.smarts,
                            rxnTags: item.tags,
                            rxnSmartsDescription: item.smarts_description
                              ? item.smarts_description
                              : "",
                          });
                          setRxnId(item.rxn_id);
                          getReactionDetail(item.rxn_id ? item.rxn_id : "");
                        }}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formRxnDescription">
                    <Form.Label>Reaction Description</Form.Label>
                    <Form.Control
                      value={rxn.rxnDescription}
                      as="textarea"
                      rows={4}
                      placeholder="Enter Reaction Description"
                      onChange={(e) =>
                        setRxn({ ...rxn, rxnDescription: e.target.value })
                      }
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formRxnSmarts">
                    <Form.Label>
                      Reaction SMARTS<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      value={rxn.rxnSmarts}
                      as="textarea"
                      placeholder="Enter SMARTS"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        setRxn({ ...rxn, rxnSmarts: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formRxnSmartsDescription"
                    onChange={(e) =>
                      setRxn({
                        ...rxn,
                        rxnSmartsDescription: e.target.value,
                      })
                    }
                  >
                    <Form.Label>SMARTS Description</Form.Label>
                    <Form.Control
                      value={rxn.rxnSmartsDescription}
                      as="textarea"
                      rows={5}
                      placeholder="Enter SMARTS"
                    />
                  </Form.Group>
                  {role == "admin" && (
                    <Form.Group controlId="taggedRXNs">
                      <Form.Label>Add Reaction Tag</Form.Label>
                      <Select
                        options={tags}
                        isMulti
                        isSearchable
                        className="mb-3"
                        placeholder="Select Tag"
                        onChange={(e) => {
                          setRxn({
                            ...rxn,
                            rxnTags: e,
                          });
                        }}
                      />
                    </Form.Group>
                  )}
                </Card.Body>
              </Card>
            </Col>

            {/*  */}
            {/*  */}
            {/* Synthon Form */}
            {/*  */}
            {/*  */}
            <Col lg="6">
              <Card className="mb-3">
                <Card.Header>
                  <Row>
                    <Col>
                      <h3>Synthons</h3>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "right" }}>
                      {syn.length < 4 && (
                        <PlusButton size={30} onClick={addSynField} />
                      )}
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Tabs
                    className="mb-3"
                    id="parentTab"
                    activeKey={currentTab}
                    justify
                  >
                    {syn.map(
                      (input, index) =>
                        index < 4 && (
                          <Tab
                            eventKey={"Synthon" + (index + 1)}
                            title={
                              <Row>
                                <Col
                                  onClick={() =>
                                    setCurrentTab("Synthon" + (index + 1))
                                  }
                                  xl
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                  }}
                                >
                                  <h5>{"Synthon " + (index + 1)}</h5>
                                </Col>
                                <Col
                                  xs={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  {syn.length > 1 && (
                                    <CloseButton
                                      size={20}
                                      onClick={() => {
                                        removeSynField(index);
                                        setCurrentTab(
                                          "Synthon" + (index === 0 ? 1 : index)
                                        );
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                            }
                            key={"Synthon" + (index + 1)}
                          >
                            <Form.Group
                              className="mb-3"
                              controlId={"synName" + (index + 1)}
                              name="synName"
                            >
                              <Form.Label>
                                Synthon Name
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <br />
                              <div className="autocomplete-wrapper">
                                <Autocomplete
                                  id={index}
                                  value={syn[index].synName}
                                  items={synthonList}
                                  getItemValue={(item) => item.synthon_name}
                                  shouldItemRender={renderSynthonName}
                                  renderInput={(props) => (
                                    <input
                                      required
                                      className="form-control"
                                      placeholder="Enter Synthon Name"
                                      {...props}
                                    />
                                  )}
                                  renderMenu={(item) => (
                                    <div className="dropdown">{item}</div>
                                  )}
                                  renderItem={(item, isHighlighted) => (
                                    <div
                                      className={`item ${
                                        isHighlighted ? "selected-item" : ""
                                      }`}
                                    >
                                      {item.synthon_name}
                                    </div>
                                  )}
                                  onChange={(event, val) => {
                                    let newSyn = [...syn];
                                    newSyn[index].synName = val;
                                    setSyn(newSyn);
                                  }}
                                  onSelect={(val, item) => {
                                    let newSyn = [...syn];
                                    newSyn[index].synName = item.synthon_name;
                                    newSyn[index].inSmarts = item.inSMARTS
                                      ? item.inSMARTS.join("\n")
                                      : "";
                                    newSyn[index].exSmarts = item.exSMARTS
                                      ? item.exSMARTS.join("\n")
                                      : "";
                                    setSyn(newSyn);
                                  }}
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId={"inSmarts"}>
                              <Form.Label>
                                InSMARTS<span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                as="textarea"
                                rows={5}
                                placeholder="Enter SMARTS"
                                value={syn[index].inSmarts}
                                onChange={(e) => synFormChange(index, e)}
                              />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId={"exSmarts"}>
                              <Form.Label>
                                ExSMARTS<span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                required
                                as="textarea"
                                rows={5}
                                placeholder="Enter SMARTS"
                                value={syn[index].exSmarts}
                                onChange={(e) => synFormChange(index, e)}
                              />
                            </Form.Group>
                          </Tab>
                        )
                    )}
                  </Tabs>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/*  */}
          {/*  */}
          {/* Submit all fields button */}
          {/*  */}
          {/*  */}
          <Row className="justify-content-md-center">
            <Col lg="3" md="auto">
              <Button variant="primary" type="submit">
                Submit Reaction and Synthons
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Base>
  );
}

export default RegisterReactionForm;
