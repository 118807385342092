import React, { useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import NavDropDown from "react-bootstrap/NavDropdown";
import useToken from "../authentication/token";
import { Toaster } from "react-hot-toast";

export default function NavigationBar() {
  const { firstName, lastName, role, token, removeToken, verifyToken } =
    useToken();

  useEffect(() => {
    // if (token) {
    //   verifyToken();
    // }
  }, []);

  return (
 
    <Navbar
      sticky="top"
      bg="dark"
      variant="dark"
      className="main-color"
      expand="lg"
    >
      <Container style={{ margin: "auto", width: "98%" }} fluid>
        <Navbar.Brand href="/">Chemistry Commons</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {/* Left Side of NavBar */}
          <Nav className="me-auto">
            <Nav.Link
              href="/reactions"
              onClick={() => {
                localStorage.removeItem("rxnData");
              }}
            >
              Reactions
            </Nav.Link>
            <Nav.Link
              href="/synthons"
              onClick={() => {
                localStorage.removeItem("synData");
              }}
            >
              Synthons
            </Nav.Link>
            <Nav.Link
             href="https://files.docking.org/chemistrycommons/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorials
            </Nav.Link>
          </Nav>
          {/* Right Side of NavBar */}
          <Nav>
            {token ? (
              <NavDropDown
                className="ml-auto"
                bg="dark"
                title={firstName + " " + lastName}
                id="basic-nav-dropdown"
              >
                {role === "admin" && (
                  <NavDropDown.Item href="/admin">Admin Menu</NavDropDown.Item>
                )}
                <NavDropDown.Item href="/user/rxnRegistrations">
                  Rxn Registration
                </NavDropDown.Item>
                <NavDropDown.Item onClick={removeToken}>
                  Logout
                </NavDropDown.Item>
              </NavDropDown>
            ) : (
              <NavDropDown
                className="ml-auto"
                bg="dark"
                title={"Login/Register"}
                id="basic-nav-dropdown"
              >
                <NavDropDown.Item href="/login">Login</NavDropDown.Item>
                <NavDropDown.Item href="/register">Register</NavDropDown.Item>
              </NavDropDown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>

      <Toaster />
    </Navbar>
  );
}
