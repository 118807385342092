import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import giraffeWalking from "./lottieJsons/giraffe.json";
import car from "./lottieJsons/car.json";
import skater from "./lottieJsons/skater.json";
import dog from "./lottieJsons/dog.json";
import burger from "./lottieJsons/burger.json";
import { Container } from "react-bootstrap";

export function LoadingScreens() {
  const [animation, setAnimation] = useState();
  const [randomNum, setRandomNum] = useState(getRandomIntInclusive(0, 4));

  function getRandomIntInclusive(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled); // The maximum is inclusive and the minimum is inclusive
  }

  function randomAnimation(num) {
    switch (num) {
      case 0:
        return car;
      case 1:
        return skater;
      case 2:
        return dog;
      case 3:
        return burger;
      default:
        return giraffeWalking;
    }
  }

  useEffect(() => {
    setAnimation(randomAnimation(randomNum));
  }, []);

  return (
    <>
      <Container style={{ margin: "auto", width: "30%", height: "30%" }}>
        <h1 className="centerText">Loading Data ...</h1>
        <Lottie animationData={animation} loop />
      </Container>
    </>
  );
}
