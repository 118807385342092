import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  Table,
  Card,
  ListGroup,
} from "react-bootstrap";
import axios from "axios";
import useToken from "../authentication/token";
import RxnTable from "../layout/components/RxnTable";
import toast from "react-hot-toast";

export default function RxnRegistrations() {
  const [rxnRegistrationDetail, setRxnRegistrationDetail] = useState({
    rxnName: "",
    rxnDescription: "",
    rxnSmarts: "",
    rxnSmartsDescription: "",
    rxnTags: "",
    rxnId: "",
  });

  const [synthonList, setSynthonList] = useState([]);

  const [rxnList, setRxnList] = useState({
    columns: [
      {
        label: "Application ID",
        field: "regId",
      },
      {
        label: "User",
        field: "username",
      },
      {
        label: "Reaction Name",
        field: "rxnName",
      },
      {
        label: "Reaction SMARTS",
        field: "rxnSmarts",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Date Submitted",
        field: "dateSubmitted",
      },
    ],
    data: [],
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (row) => {
    getRxnRegistrationDetail(row.regId);
    setShow(true);
  };
  const [loading, setLoading] = useState(false);

  async function getRxnRegistration() {
    setLoading(true);
    await axios({
      method: "GET",
      url: "/rxnRegistration/registrationList",
    }).then((res) => {
      setRxnList({ ...rxnList, data: res.data.applications });
    });
    setLoading(false);
  }

  function getRxnRegistrationDetail(id) {
    axios({
      method: "GET",
      url: "/rxnRegistration/registrationDetail/" + id,
    })
      .then((res) => {
        setSynthonList(res.data.synthons);
        setRxnRegistrationDetail({
          ...rxnRegistrationDetail,
          regId: res.data.regId,
          rxnName: res.data.rxnName,
          rxnDescription: res.data.rxnDescription,
          rxnSmarts: res.data.rxnSmarts,
          rxnSmartsDescription: res.data.rxnSmartsDescription,
          rxnTags: res.data.rxnTags,
          email: res.data.email,
          dateSubmitted: res.data.dateSubmitted,
          status: res.data.status,
          user: res.data.username,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function approveRxnRegistration(id) {
    setLoading(true);
    await axios({
      method: "POST",
      url: "/rxnRegistration/approve/" + id,
    })
      .then((res) => {
        getRxnRegistration();
        toast.success(res.data.msg);
        handleClose();
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
    setLoading(false);
  }

  function rejectRxnRegistration(id) {
    axios({
      method: "POST",
      url: "/rxnRegistration/reject/" + id,
    })
      .then((res) => {
        getRxnRegistration();
        alert(res.data.msg);
        handleClose();
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  }

  function SynthonList() {
    const displaySynthons = synthonList.map((synthons, index) => (
      <Row key={"synthon" + index} className="mb-3">
        <Col>
          <Card>
            <Card.Header>{synthons.synName}</Card.Header>
            <Card.Body>
              <Row>
                <Card.Title>Inclusion Smarts</Card.Title>
                <ListGroup variant="flush">
                  {synthons.inSmarts.map((insyn, index) => {
                    return (
                      <ListGroup.Item key={"inSmarts" + index}>
                        {insyn}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
                <Card.Title>Exclusion Smarts</Card.Title>
                <ListGroup variant="flush">
                  {synthons.exSmarts.map((exsyn, index) => {
                    return (
                      <ListGroup.Item key={"exSmarts" + index}>
                        {exsyn}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    ));

    return displaySynthons;
  }

  useEffect(() => {
    getRxnRegistration();
  }, []);
  return (
    <>
      <RxnTable
        striped
        bordered
        hover
        data={rxnList}
        loading={loading}
        actions={{
          view: true,
          viewLogic: (row) => handleShow(row),
        }}
      ></RxnTable>
      <Modal size="xl" backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title as={"h2"}>Registration Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h2 className="centerText">Reaction</h2>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>Registration ID</th>
                <td>{rxnRegistrationDetail.regId}</td>
              </tr>
              <tr>
                <th>Reaction Name</th>
                <td>{rxnRegistrationDetail.rxnName}</td>
              </tr>
              <tr>
                <th>Reaction Description</th>
                <td>{rxnRegistrationDetail.rxnDescription}</td>
              </tr>
              <tr>
                <th>Reaction SMARTS</th>
                <td>{rxnRegistrationDetail.rxnSmarts}</td>
              </tr>
              <tr>
                <th>SMARTS Description</th>
                <td>{rxnRegistrationDetail.rxnSmartsDescription}</td>
              </tr>
              <tr>
                <th>Reaction Tags</th>
                <td>{rxnRegistrationDetail.rxnTags}</td>
              </tr>
              <tr>
                <th>Submitted by user</th>
                <td>{rxnRegistrationDetail.user}</td>
              </tr>
              <tr>
                <th>Submitted on</th>
                <td>{rxnRegistrationDetail.dateSubmitted}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>{rxnRegistrationDetail.status}</td>
              </tr>
            </tbody>
          </Table>
          <h2 className="centerText">Synthons</h2>
          <SynthonList />
        </Modal.Body>
        <Modal.Footer>
          <Col>
            {rxnRegistrationDetail.status !== "Rejected" && (
              <Button
                variant="danger"
                type="submit"
                className=""
                onClick={() =>
                  rejectRxnRegistration(rxnRegistrationDetail.regId)
                }
              >
                Reject
              </Button>
            )}
          </Col>
          <Col className="rightText">
            {rxnRegistrationDetail.status === "Submitted" && (
              <Button className="mx-1" variant="primary" type="submit">
                Run Enumeration
              </Button>
            )}
            {rxnRegistrationDetail.status !== "Approved" && (
              <Button
                disabled={loading}
                variant="primary"
                type="submit"
                onClick={() =>
                  approveRxnRegistration(rxnRegistrationDetail.regId)
                }
              >
                Approve
              </Button>
            )}
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
}
