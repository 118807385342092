import React, { useEffect, useState } from "react";
import { Button, Row, ButtonGroup } from "react-bootstrap";
import Base from "../layout/BaseTemplate";
import useToken from "../authentication/token";
import { useNavigate } from "react-router-dom";
import RxnRegistrations from "./ManageRxnRegistration";
import ManageReactions from "./ManageReactions";
import TagsList from "./ManageTags";
import Roles from "./ManageRoles";
import Users from "./ManageUsers";

export default function AdminLandingPage() {
  // Authentication Check
  const { token, verifyToken, kickOutGuest, role } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    if (role !== "admin") {
      navigate("/");
    }
    token ? verifyToken() : kickOutGuest();
  }, []);

  const [tab, setTab] = useState(1);

  return (
    <Base>
      <Row className="centerText">
        <ButtonGroup>
          <Button
            id={1}
            active={tab === 1}
            onClick={() => {
              setTab(1);
            }}
          >
            Manage Reaction Registration
          </Button>
          <Button
            id={2}
            active={tab === 2}
            onClick={() => {
              setTab(2);
            }}
          >
            Manage Reactions
          </Button>
          <Button
            id={3}
            active={tab === 3}
            onClick={() => {
              setTab(3);
            }}
          >
            Manage Tags
          </Button>
          <Button
            id={4}
            active={tab === 4}
            onClick={() => {
              setTab(4);
            }}
          >
            Manage Roles
          </Button>
          <Button
            id={5}
            active={tab === 5}
            onClick={() => {
              setTab(5);
            }}
          >
            Manage Users
          </Button>
        </ButtonGroup>
      </Row>
      <br />
      {tab == 1 && <RxnRegistrations />}
      {tab == 2 && <ManageReactions />}
      {tab == 3 && <TagsList />}
      {tab == 4 && <Roles />}
      {tab == 5 && <Users />}
    </Base>
  );
}
