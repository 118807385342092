import React from "react";
import Base from "../layout/BaseTemplate";
import toast from "react-hot-toast";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import useToken from "./token";

function ForgotPassword() {
  const [userEmail, setEmail] = useState("");
  const [validated, setValidated] = useState(false);

  return (
    <Base>
      <Row className="justify-content-md-center">
        <Col lg="5">
          <Container fluid className="page-content">
            <Card className="text-center">
              <Card.Header>
                <h1>Forgot Password</h1>
              </Card.Header>
              <Card.Body>
                <Form noValidate validated={validated}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Enter email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button className="mb-3" variant="primary" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </Base>
  );
}

export default ForgotPassword;
